/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(document).foundation(); // Foundation JavaScript
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $(function() {
          /*$('.trigger-product-dropdown-menu, .product-dropdown-menu').on("mouseenter", function(e) {
            $('.product-dropdown-menu').addClass('active');
            e.stopPropagation()
          });*/
          $('.trigger-product-dropdown-menu a').on("mouseenter", function(e) {
            $('.product-dropdown-menu').addClass('active');
            e.stopPropagation()
          });
          $('.product-dropdown-menu').on("mouseenter", function(e) {
            $('.product-dropdown-menu').addClass('active');
            e.stopPropagation()
          });
          $('.trigger-product-dropdown-menu, .product-dropdown-menu').on("mouseleave", function(e) {
            $(".product-dropdown-menu").removeClass("active");
          });
          $(document).on("click", function(e) {
            if ($(e.target).is(".product-dropdown-menu") === false) {
              $(".product-dropdown-menu").removeClass("active");
            }
          });
        });

        $("[name='addon-10401-sds5000x-software-bundle-promotion-0[]']").prop('checked', true);

        // Show Product menu
        //$('.trigger-product-dropdown-menu').click(function(event) {
        //  $('.product-dropdown-menu').toggleClass('active');
        //});

        // Scroll To - adds a smooth scrolling to anchored sections using .scroll-to (also works on .scroll-to a)
        $(".scroll-to, .scroll-to-li a").not('[href="#"]').not('[href="#0"]').click(function(t){if(location.pathname.replace(/^\//,"")===this.pathname.replace(/^\//,"")&&location.hostname===this.hostname){var e=$(this.hash);(e=e.length?e:$("[name="+this.hash.slice(1)+"]")).length&&(t.preventDefault(),$("html, body").animate({scrollTop:e.offset().top-60},1e3,function(){var t=$(e);if(t.focus(),t.is(":focus"))return!1;t.attr("tabindex","-1"),t.focus()}))}});

        $('.product-image-slider').slick({
          autoplay: false,
          arrows: true,
          dots: true,
          infinite: true,
          speed: 1000,
        });

        $('.slide-zoom img').wrap('<span style="display:inline-block"></span>').css('display', 'block').parent().zoom();

        $('.product-page-characteristics-grid').readmore({
          collapsedHeight: 300,
          moreLink: '<a href="#" class="view-more">View More</a>',
          lessLink: '<a href="#" class="view-more">View Less</a>'
        });


        if ( $('#countryModal').length ){

          //if(getCookie("seenPopUp") != 'yes'){
          //if(getCookie("seenPopUp") == 'yes'){
          //if(localStorage.getItem('popState') != 'shown'){
            $('#countryModal').foundation('open');
            //localStorage.setItem('popState','shown')
            //document.cookie = "seenPopUp=yes; max-age=" + 1*24*60*60;
            //createCookie('seenPopUp', 'yes', 5)
          //}

        }

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init

        $('.home-product-banner').slick({
          autoplay: true,
          arrows: false,
          dots: true,
          infinite: true,
          speed: 700,
          autoplaySpeed: 5500,
        });
        
        $('.offer-carousel').slick({
          autoplay: true,
          arrows: false,
          dots: true,
          infinite: true,
          speed: 500,
        });

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'how_to_buy': {
      init: function() {
    
        $('.modal-region').mouseenter(function() {
          console.log( $(this).attr('id') );
        });

        $('.modal-region').click(function() {
          var modalId = '#modal-' + $(this).attr('id');
          console.log(modalId);
          var modal = new Foundation.Reveal($(modalId));
          modal.open();
        });

      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

jQuery('.front-to-rear-control').click(function() {
  jQuery('.front-to-rear-img').toggleClass('active');
});


function createCookie(name, value, days) {
  var expires;
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toGMTString();
  }
  else {
      expires = "";
  }
  document.cookie = name + "=" + value + expires + "; path=/";
}

function getCookie(c_name) {
  if (document.cookie.length > 0) {
      c_start = document.cookie.indexOf(c_name + "=");
      if (c_start != -1) {
          c_start = c_start + c_name.length + 1;
          c_end = document.cookie.indexOf(";", c_start);
          if (c_end == -1) {
              c_end = document.cookie.length;
          }
          return unescape(document.cookie.substring(c_start, c_end));
      }
  }
  return "";
}

